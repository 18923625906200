<template>
  <v-container>
    <v-card height="calc(100vh - 110px)">
      <v-row no-gutter style="height: 100%">
        <v-col style="max-width: 300px">
          <v-list>
            <v-list-item>
              <SearchField
                v-model="filters.search"
                @search="query()"
                class="mb-3"
              ></SearchField>
            </v-list-item>
            <v-divider></v-divider>
            <v-list style="max-height: calc(100vh - 195px); overflow-y: auto">
              <template v-for="category in data">
                <v-list-item
                  :key="category.id"
                  :to="{
                    name: 'form-options-category',
                    params: { category_id: category.id },
                  }"
                  color="primary"
                  nav
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ category.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-divider :key="`divider_${category.id}`"></v-divider> -->
              </template>
            </v-list>
          </v-list>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col>
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import QueryMixin from "@/mixins/QueryMixin.vue";
import SearchField from "@/components/SearchField.vue";
import { indexCategories } from "@/services/config/form_options";

export default {
  mixins: [QueryMixin],

  components: { SearchField },

  props: {},

  data() {
    return {};
  },

  methods: {
    responseToData(response) {
      return response.data;
    },

    async getQuery(filters) {
      return indexCategories(filters.search || "");
    },
  },

  computed: {},

  created() {
    this.query();
  },
};
</script>
