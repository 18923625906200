<template>
  <v-text-field
    v-model="value"
    @click:clear="handleClear()"
    outlined
    single-line
    dense
    prepend-inner-icon="mdi-magnify"
    label="Pesquisar"
    hide-details
    v-bind="$attrs"
    clearable
  ></v-text-field>
</template>

<script>
export default {
  mixins: [],

  components: {},

  props: {
    urlKey: {
      type: String,
      default: "search",
    },
    search: {
      type: String,
      default: "",
    },
  },

  model: {
    prop: "search",
    event: "search",
  },

  data() {
    return {
      value: "",
    };
  },

  methods: {
    handleUpdate() {
      this.$emit("search", this.value);
    },
    handleClear() {
      this.value = "";
    },
  },

  computed: {},

  watch: {
    value: _.debounce(async function (value) {
      this.handleUpdate();
    }, 500),
  },
};
</script>
