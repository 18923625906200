<script>
export default {
  mixins: [],

  components: {},

  props: {},

  data() {
    return {
      loading: false,
      ready: false,
      data: [],
      error: false,
      filters: {},
    };
  },

  methods: {
    responseToData(response) {
      return response;
    },

    async getQuery(filter) {},

    handleNotFound(error) {},

    onError(error) {},

    async refresh() {
      this.query();
    },

    async query() {
      this.loading = true;
      this.data = [];
      this.error = false;
      this.ready = false;

      try {
        this.data = this.responseToData(await this.getQuery(this.filters));
        this.ready = true;
        this.error = false;
      } catch (error) {
        this.error = true;
        if (error.response.status === 404) {
          this.handleNotFound(error);
          return;
        }
        this.onError(error);
      } finally {
        this.loading = false;
      }
    },

    async clear() {
      this.filters = {};
      this.$nextTick(() => {
        this.query();
      });
    },
  },
};
</script>
