import Vue from "vue";

export async function indexCategories(search) {
  const response = await Vue.prototype.$http.get(
    "/configs/form_options/categories",
    {
      params: {
        search,
      },
    }
  );

  return response.data;
}

export async function showCategory(id) {
  const response = await Vue.prototype.$http.get(
    `/configs/form_options/categories/${id}`
  );

  return response.data;
}

export async function storeOption(payload) {
  const response = await Vue.prototype.$http.post(
    `/configs/form_options/options`,
    payload
  );

  return response.data;
}

export async function updateOption(payload, id) {
  const response = await Vue.prototype.$http.put(
    `/configs/form_options/options/${id}`,
    payload
  );

  return response.data;
}

export async function deleteOption(id) {
  const response = await Vue.prototype.$http.delete(
    `/configs/form_options/options/${id}`
  );

  return response.data;
}

export async function storeOptionCode(option_id, payload) {
  const response = await Vue.prototype.$http.post(
    `/configs/form_options/options/${option_id}/codes`,
    payload
  );

  return response.data;
}

export async function destroyOptionCode(option_id, lead_provider_id) {
  const response = await Vue.prototype.$http.delete(
    `/configs/form_options/options/${option_id}/codes/${lead_provider_id}`
  );

  return response.data;
}
